
import { EasemobChat } from "easemob-websdk/Easemob-chat";
import { Options, Vue, setup } from "vue-class-component";
import { NavBar, Button, CellGroup, Field, Toast } from "vant";
import { useStore } from "vuex";
import { onMounted, reactive } from "vue";
import { AllState } from "../../store";
import router from "@/router";

@Options({
  components: {
    NavBar,
    Button,
    CellGroup,
    Field
  }
})
export default class User extends Vue {
  user = setup(() => {
    const store = useStore<AllState>();
    let info = reactive<EasemobChat.UpdateOwnUserInfoParams>({});
    const conn = store.state.IM.connect;
    const getUserInfo = () => {
      conn.fetchUserInfoById(conn.user).then((res) => {
        Object.assign(info, res.data?.[conn.user]);
      });
    };

    const updateUserInfo = (
      options: EasemobChat.UpdateOwnUserInfoParams,
      value: string
    ) => {
      conn
        .updateOwnUserInfo(options, value)
        .then(() => {
          Toast("更新用户信息成功");
        })
        .catch(() => {
          Toast("更新用户信息失败");
        });
    };

    const logout = () => {
      conn.close();
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("uid");
      router.push("/login");
    };

    const setting = () => {
      router.push("/setting");
    };

    const deleteAllMessages = () => {
      conn
        .deleteAllMessagesAndConversations()
        .then(() => {
          console.log("清除聊天记录成功");
        })
        .catch((e:any) => {
          console.log("清除聊天记录失败", e);
        });
    };

    onMounted(() => {
      getUserInfo();
    });

    return {
      userId: conn.user,
      info: info,
      updateUserInfo,
      deleteAllMessages,
      logout,
      setting
    };
  });
}
